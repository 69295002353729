import {motion} from 'framer-motion';
import styled from 'styled-components';

export const RobotAnimationWrapper = styled(motion.svg)`
  overflow: visible;
`;

export const Head = styled(motion.rect)``;
export const Body = styled.path``;
export const Wheel = styled.circle``;
