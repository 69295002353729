import {useCallback, useEffect} from 'react';

import {useAnimation} from 'framer-motion';

import {COLOR_BLACK, COLOR_ORANGE} from '@/theme/colors';
import {EASE_ON} from '@/theme/transitions';

import {RobotAnimationWrapper, Head, Wheel, Body} from './styles';

interface Props {
  width: string;
  height: string;
}

const wrapperVariants = {
  entrance: {
    x: '-25vw',
    rotate: '-3deg',
  },
  greetingStart: {
    x: '26vw',
    rotate: '0deg',
    transition: {
      duration: 2.2,
      ease: 'easeInOut',
    },
  },
  greetingNod: {
    x: '26vw',
    rotate: '18deg',
    transition: {
      ease: [0.85, 0.33, 0.7, 0.78],
    },
  },
  hesitateStart: {
    x: '41.8vw',
    rotate: '15deg',
    transition: {
      duration: 1.3,
      ease: 'easeInOut',
    },
  },
  hesitateEnd: {
    x: '42.1vw',
    rotate: '15deg',
    transition: {
      bounce: 0.25,
      type: 'spring',
    },
  },
  goodbyeStart: {
    x: '62vw',
    rotate: '0deg',
    transition: {
      duration: 1.1,
    },
  },
  goodbyeEnd: {
    x: '63vw',
    rotate: '18deg',
    transition: {
      duration: 0.5,
    },
  },
  disappear: {
    x: '100vw',
    rotate: '8deg',
    transition: {
      duration: 1.3,
    },
  },
};

const headVariants = {
  entrance: {
    rotate: '-27.536deg',
    x: 1,
    y: 5,
  },
  greetingStart: {
    rotate: '-27.536deg',
    x: 1,
    y: 5,
  },
  greetingNod: {
    rotate: '-12.0536deg',
    x: 6,
    y: 1,
    transition: {
      bounce: 0.65,
      type: 'spring',
      delay: 0.1,
    },
  },
  goodbyeStart: {
    rotate: '-27.8536deg',
    x: 1,
    y: 5,
    transition: {
      duration: 0.5,
      ease: EASE_ON,
    },
  },
  goodbyeEnd: {
    rotate: '-13.4536deg',
    x: 6,
    y: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const DEBUG_ANIMATION = false;

const RobotAnimation = ({width, height}: Props) => {
  const wrapperControls = useAnimation();

  const sequence = useCallback(async () => {
    await wrapperControls.set('entrance');
    await wrapperControls.start('greetingStart');
    await wrapperControls.start('greetingNod');
    await wrapperControls.start('greetingEnd');
    await wrapperControls.start('hesitateStart');
    await wrapperControls.start('hesitateEnd');
    await wrapperControls.start('goodbyeStart');
    await wrapperControls.start('goodbyeEnd');
    await wrapperControls.start('disappear');

    // Restart loop
    sequence();
  }, [wrapperControls]);

  useEffect(() => {
    sequence();
  }, [wrapperControls, sequence]);

  return (
    <>
      <RobotAnimationWrapper
        animate={wrapperControls}
        variants={wrapperVariants}
        initial="entrance"
        viewBox="0 0 46 117"
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Wheel cx="12.5" cy="104.5" r="12.5" fill={COLOR_ORANGE} />
        <Body fill={COLOR_BLACK} d="M9 34.9l16.4-4.1L45 109.4l-16.5 4z" />
        <Head
          variants={headVariants}
          x="15"
          y="10.3894"
          width="23"
          height="23"
          style={{originX: '15%', originY: '10.3894%'}}
          fill={COLOR_BLACK}
          d="M15 10.4L35.5 0 46 20.5 25.4 31z"
        />
      </RobotAnimationWrapper>
      {DEBUG_ANIMATION && (
        <div
          style={{
            position: 'fixed',
            top: 120,
            right: '30px',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
          }}
        >
          {Object.keys(wrapperVariants).map((v) => (
            <button key={v} onClick={() => wrapperControls.start(v)}>
              {v}
            </button>
          ))}
          <button onClick={() => sequence()}>Sequence</button>
        </div>
      )}
    </>
  );
};

export default RobotAnimation;
