import Heading from '@/components/Heading';
import Arrow from '@/components/icons/Arrow';
import RobotAnimation from '@/components/RobotAnimation';
import {eventClick404Link} from '@/config/tracking';
import {FourZeroFour} from '@/content/cms/types';
import {pxToRem} from '@/theme/grid';
import {Direction} from '@/utils/svgrProps';

import {StyledButton} from './styles';

interface Props {
  content: FourZeroFour;
}

const FourZeroFourPageContent = ({content}: Props) => (
  <>
    <RobotAnimation width={pxToRem(45)} height="100%" />
    <Heading level={2} animate>
      {content.heading}
    </Heading>
    <Heading level={5} animate>
      {content.description}
    </Heading>
    <StyledButton
      iconPosition="right"
      animateArrow
      tracking={eventClick404Link(content.link.title)}
      link={content.link}
    >
      {content.link.title}
      <Arrow direction={Direction.Right} />
    </StyledButton>
  </>
);

export default FourZeroFourPageContent;
