import {GetStaticProps} from 'next';

import FourZeroFourPageContent from '@/components/FourZeroFourPageContent';
import PageLayout from '@/components/PageLayout';
import {SLUG_404} from '@/config/routes';
import {getGlobals, getPage, overrideMeta} from '@/content/cms';
import {Globals, FourZeroFour, Page} from '@/content/cms/types';
import useDraftArticleProps from '@/hooks/useDraftArticleProps';
import useFetch from '@/hooks/useFetch';

import ArticlePage from './thinking/[slug]';
interface Props {
  globals: Globals;
  page: Page<FourZeroFour>;
}

const fetchProps = async (token?: string) => {
  const globals = await getGlobals(token);
  const page = await getPage<FourZeroFour>(SLUG_404, token);
  return {globals, page} as Props;
};

const FourZeroFourPage = (props: Props) => {
  const {globals, page} = useFetch<Props>(props, fetchProps);
  overrideMeta(page, globals);
  const {draftProps, loading} = useDraftArticleProps();

  if (loading) return null;

  if (draftProps) {
    return <ArticlePage {...draftProps} />;
  }

  return (
    <PageLayout page={page} globals={globals}>
      <FourZeroFourPageContent content={page.content} />
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  return {props: await fetchProps()};
};

export default FourZeroFourPage;
