import {useEffect, useState} from 'react';

import {
  Props as ArticleProps,
  fetchPropsWithParams,
} from '@/pages/thinking/[slug]';

/**
 * Checks for 'previewToken' and 'slug' in url query params
 * if present fetches and returns article preview props
 */
const useDraftArticleProps = () => {
  const [draftProps, setDraftProps] = useState<ArticleProps>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('previewToken') as string;
    const slug = params.get('slug') as string;
    if (token && slug) {
      const fetchProps = fetchPropsWithParams(slug);

      const fetchArticleProps = async () => {
        const newArticleProps = await fetchProps(token);
        setDraftProps(newArticleProps);
        setLoading(false);
      };

      fetchArticleProps();
    } else {
      setLoading(false);
    }
  }, []);

  return {draftProps, loading};
};

export default useDraftArticleProps;
